<template>
  <div class="bg">
    <div class="get-app">
      <div class="content">
        <div class="install-app">
          <h3 class="big-title">Start Trading to Earn Rewards</h3>
          <p class="desc">
            Download the Giftcardstonaira app to sell your gift cards on the go
          </p>
          <div class="app-download">
            <GetAppButton title="Get it on iOS" icon="ios" />
            <GetAppButton title="Get it on Android" icon="android" />
          </div>
        </div>
        <div class="app-screenshot"></div>
      </div>
    </div>

    <div class="bottom">
      <!-- <div class="app-desc">
        <div class="app-title-bg">
          <img src="../assets/logo.png" alt="" sizes="10" srcset="">
          <h4 class="app-title">Migo</h4>
        </div>
        <div style="margin-top: 3rem !important;">
          <h6 class="title1">{{ appDesc }}</h6>
          <p class="title2">{{ abilityDesc }}</p>
          <p class="title2">D-U-N-S Number：663104379</p>
          <p class="title2">Company Name：NEXIUM LTD</p>
          <p class="title2">Company Address：306 Victoria House Mahe Seychelles</p>
        </div>
      </div> -->
      <div class="bg-copy-right" :style="{ 'flex-direction': flexDirection }">
        <div class="item-bg" v-for="(item, index) in arr" :key="index">
          <div class="title">{{ item.title }}</div>
          <a class="catorgry" v-for="(title, i) in item.contentArr" :key="i" @click="handelClick(title)">
            {{ title }}</a>
        </div>
      </div>
      <div class="copyright">{{ rightDesc }}</div>
    </div>
  </div>
</template>

<script>
import GetAppButton from "./GetAppButton.vue"

import Config from '../URL_Config'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  components: {
    GetAppButton,
  },
  data: () => ({
    arr: [
      {
        title: "Features",
        contentArr: [
          "Rate Calculator",
          "Giftcardstonaira Mobile App",
          "Rewards",
        ],
      },
      {
        title: "Resources",
        contentArr: [
          // "Blog",
          // "Videos",
          "How To Sell Steam Gift Card",
          "How To Sell Google Play Gift Card",
          "How To Sell Apple iTunes Gift Card",
          "How To Sell Amazon Gift Card",
        ],
      },
      {
        title: "Company",
        contentArr: ["About Us", "Terms", "Privacy Policy", "AML Policy"],
      },
      {
        title: "Support",
        contentArr: ["FAQs", "Contact Us"], // "Visit Help Centre", "Contact Us"
      },
      { title: "Follow", contentArr: ["Instagram", "Twitter", "Telegram"] },
    ],
    appDesc:
      "NEXIUM LTD is a registered gift card trading company established in 2017 dedicated to providing world-class trading experience to people in Nigeria and Ghana.",
    abilityDesc:
      "Integrity, dedication and excellence in value have been the pillars to which our company hinges its practices and policies on.",
    rightDesc:
      "All Rights Reserved. All third-party trademarks appearing on this site are the property of their respective owners. GiftCardsToNaira has no relationship with any third party who have trademarks or gift cards appearing on this site.",
    isFlexDirctionRow: true,
    flexDirection: "row",
  }),
  mounted() {
    this.updateFeatureFlexDesc(document.body.clientWidth > 900);
    let that = this;
    window.onresize = () => {
      // console.log(document.body.clientWidth);
      that.updateFeatureFlexDesc(document.body.clientWidth > 900);
    };
  },
  methods: {
    updateFeatureFlexDesc(isFlexDirctionRow) {
      if (this.isFlexDirctionRow != isFlexDirctionRow) {
        this.isFlexDirctionRow = isFlexDirctionRow;
        if (isFlexDirctionRow == 1) {
          this.flexDirection = "row";
        } else {
          this.flexDirection = "column";
        }
        console.log(this.flexDirection);
      }
    },
    handelClick(t) {
      console.log(t)

      const action = {
        "Rate Calculator": 'Rate',
        "Giftcardstonaira Mobile App": 'Download',
        "Rewards": 'Rewards',
        "How To Sell Steam Gift Card": "Steam",
        "How To Sell Google Play Gift Card": "Google",
        "How To Sell Apple iTunes Gift Card": "Apple",
        "How To Sell Amazon Gift Card": "Amazon",
        "About Us": "About", "Terms": "Terms", "Privacy Policy": "Policy", "AML Policy": "AML",
        "FAQs": 'FAQS', "Contact Us": 'Contact'
      }

      let target = action[t]
      if (target != undefined) {

        if (this.$route.name != target) {
          this.$router.push({ name: target })
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }, 300);
        }
        return
      }

      const url = Config[t] 
      if (url) {
        // window.location.href = url
        window.open(url, '_blank')
        return
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: relative;

  .bottom {
    background-color: #F5F5FE;
  }
}

.big-title {
  font-weight: 600;
  font-size: 4rem;
  text-align: left;
  line-height: normal;
  color: #fffffe;
  word-wrap: break-word;
}

.desc {
  font-size: 1.5rem;
  text-align: left;
  color: #c2c1f9;
  line-height: 1.6rem;
  font-weight: 400;
  word-wrap: break-word;
}

.app-download {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 360px;
  gap: 1rem;
  justify-content: space-around;

  margin-left: auto;
  margin-right: auto;
}

.get-app {
  padding: 5rem 3.667rem 3.25rem;

  .content {

    display: flex;
    align-content: center;
    flex-direction: row;
    background-color: #5352EC;

    border-radius: 1.875rem;
    overflow: hidden;
    background-color: #5352EC;
    width: 100%;
    padding: 3rem 1rem 1rem;

    text-align: center;

    .big-title {
      justify-self: center;
    }

    .desc {
      justify-self: center;
    }

    .app-screenshot {
      align-self: center;
      margin-left: 10px;
      margin-top: 2rem;

      position: relative;
      height: 35.25rem;
      width: 100%;
      display: flex;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      background-image: url(../assets/dashboard.png);
    }
  }
}

.app-desc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 5rem;

  .app-title-bg {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 2rem;
      margin-right: 1rem;
    }

    .app-title {
      display: inline-block;
      font-size: 2rem;
      font-weight: 500;
    }

  }

  .title1 {
    font-size: 1rem;
    text-align: left;
    color: #040417;
    line-height: 1.5rem;
    padding-right: 10%;
    font-weight: 500;
    margin-bottom: .5rem;
  }

  .title2 {
    font-size: 1rem;
    text-align: left;
    color: #040417;
    line-height: 1.5rem;
    padding-right: 10%;
    font-weight: 500;
    margin-top: 1rem !important;
    margin-bottom: 16px
  }
}

.bottom {
  padding: 5rem 3.667rem 3.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}


.bg-copy-right {
  font-family: Roobert, sans-serif !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #E6E6E6;

  .item-bg {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    gap: 1rem;
    align-items: flex-start;
    margin-top: 2.5rem;

    .catorgry {
      font-size: .9rem;
      line-height: 1.475rem;
      text-decoration: none;
      color: #040417;
      font-weight: 500;
    }

    .title {
      font-size: .9rem;
      line-height: 1.475rem;
      text-decoration: none;
      color: #040417;
      font-weight: 500;
      color: #999;
    }
  }
}

.copyright {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #999;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
}

span {
  color: black;
}

@media only screen and (max-width: 900px) {
  .bg-copy-right {
    .item-bg {
      .catorgry {
        font-size: 1.2rem;
      }

      .title {
        font-size: 1.2rem;
      }
    }
  }

  .get-app {
    .content {
      flex-direction: column;

      .install-app {

        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .app-download {
          flex-direction: column;
        }
      }

      .app-screenshot {
        background-position: center;
      }
    }
  }
}

@media only screen and (max-width: 580px) {


  .get-app {
    padding: 3rem 1rem 0rem;
    gap: 2.625rem;

    .content {
      flex-direction: column;

      .install-app {

        text-align: center;
        align-items: center;
        justify-content: center;

        .big-title {
          font-size: 3rem;
        }

        .app-download {
          flex-direction: row;
        }
      }

      .app-screenshot {
        background-position: center;
      }

      .big-title {}
    }
  }

  .bottom {
    padding: 3rem 1.5rem 2rem;
    gap: 0rem;

    .app-desc {
      justify-content: center;
      align-items: center;
      padding-bottom: 3rem;

      .title1 {
        text-align: center;
      }

      .title2 {
        text-align: center;
      }
    }


    .bg-copy-right {
      .item-bg {
        padding-bottom: 2rem;
      }
    }
  }

  .copyright {
    text-align: center;
  }
}
</style>
